
import Vue from 'vue'
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { User } from '@/entities/User';
import { UserData } from '@/plugins/services/users';

@Component({
  computed: mapGetters([
    'user'
  ])
})
export default class AccountEditView extends Vue {
  name = "";
  nameRules = [
    (v: string) => v.length > 6 || 'Name is too short',
    (v: string) => v.length < 64 || 'Name is too long'
  ];

  password = "";
  passwordRules = [
    (v: string) => v.length > 8 || 'Password is too short',
    (v: string) => v.length < 64 || 'Password is too long'
  ];

  user!: User;

  mounted() {
    this.name = this.user.name;
  }

  $refs!: {
    form: HTMLFormElement
  }

  async saveAccount() {
    let data: UserData = {};

    if (this.name.length > 0) data.name = this.name;
    if (this.password.length > 0) data.password = this.password; 

    this.$users.edit(this.user.id, data);
  }
}
